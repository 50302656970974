import React, { FC } from 'react';
import { isPast, isToday, format } from 'date-fns';

import AwaitingConfirmation from '@/components/common/Checkins/Boxs/Cards/AwaitingConfirmation';
import Canceled from '@/components/common/Checkins/Boxs/Cards/Canceled';
import Confirmed from '@/components/common/Checkins/Boxs/Cards/Confirmed';
import Done from '@/components/common/Checkins/Boxs/Cards/Done';
import Pending from '@/components/common/Checkins/Boxs/Cards/Pending';

import { getFormattedDate } from '@/utils/getFormattedDate';
import { getFirstNameAndLastName } from '@/utils/getFirstNameAndLastName';

import { Product } from '@/types/product';
import { Status } from '@/types/status';

import { OnOpenModalTypeProps } from './types';

export interface CardProps {
  id: string;
  name: string;
  document: string;
  product: Product;
  startReservationHour: string;
  endReservationHour: string;
  status: Status;
  onOpenModalType: (data: OnOpenModalTypeProps, types: string) => void;
  createdAt?: string;
  innerRef?: (node?: Element | null | undefined) => void;
}

export const Card: FC<CardProps> = ({
  id,
  name,
  document,
  product,
  status: statusBase,
  startReservationHour,
  endReservationHour,
  onOpenModalType,
  createdAt,
  innerRef,
}) => {
  const date = new Date(startReservationHour ?? new Date());
  const firstNameAndLastName = getFirstNameAndLastName(name);

  const status =
    statusBase === 'PENDING' && isPast(date) && !isToday(date)
      ? 'EXPIRED'
      : statusBase;

  const handleOnClick = (type: string) =>
    onOpenModalType(
      {
        id,
        name,
        status,
        startReservationHour,
        endReservationHour,
        document,
        product,
        createdAt,
      },
      type
    );

  const handleConfirm = () => handleOnClick('confirm');
  const handleAwaitingConfirmation = () =>
    handleOnClick('awaiting_confirmation');
  const handlePendingClosure = () => handleOnClick('closure');
  const handlePendingClose = () => handleOnClick('close');

  const formattedDate = getFormattedDate(date);
  const formattedTime = `${format(
    new Date(startReservationHour),
    'HH:mm'
  )} às ${format(new Date(endReservationHour), 'HH:mm')}`;

  return (
    <>
      {status === 'AWAITING_CONFIRMATION' && (
        <AwaitingConfirmation
          name={firstNameAndLastName}
          activity={product?.name}
          date={formattedDate}
          time={formattedTime}
          onAccept={() => handleAwaitingConfirmation()}
          onReject={() => handleAwaitingConfirmation()}
          ref={innerRef}
        />
      )}

      {status === 'PENDING' && (
        <Pending
          name={firstNameAndLastName}
          activity={product?.name}
          date={formattedDate}
          time={formattedTime}
          onAccept={() => handlePendingClosure()}
          onReject={() => handlePendingClose()}
          ref={innerRef}
        />
      )}

      {status === 'CONFIRMED' && (
        <Confirmed
          name={firstNameAndLastName}
          activity={product?.name}
          date={formattedDate}
          time={formattedTime}
          onAccept={() => handleConfirm()}
          ref={innerRef}
        />
      )}

      {status === 'DONE' && (
        <Done
          name={firstNameAndLastName}
          activity={product?.name}
          date={formattedDate}
          time={formattedTime}
          ref={innerRef}
        />
      )}

      {status === 'CANCELED' && (
        <Canceled
          name={firstNameAndLastName}
          activity={product?.name}
          date={formattedDate}
          time={formattedTime}
          ref={innerRef}
        />
      )}
    </>
  );
};

export default Card;
