import React, { FC } from 'react';

import { Loading } from '@/components/common/Loading';

import { formatCpf } from '@/utils/formatCpf';
import { formatCode } from '@/utils/formatCode';

import { AiFillCheckCircle, AiOutlineClose } from 'react-icons/ai';
import { FaUserCircle } from 'react-icons/fa';

import { ModalConfirmProps } from './types';
import useModalConfirmController from './useModalConfirm';

import {
  Container,
  ContentModal,
  Header,
  Name,
  Document,
  Description,
  Content,
  Reserve,
  Tag,
  CodeNumber,
  Infos,
  Input,
  BottomContent,
  IconInput,
  ButtonClose,
} from './styles';

export const ModalConfirm: FC<ModalConfirmProps> = ({
  userData,
  open,
  onClose,
  startReservationHour,
  getIncome,
}) => {
  const {
    handleClose,
    reservationDate,
    isFirst,
    confirmationCode,
    setConfirmationCode,
    loading,
    onApplyCode,
  } = useModalConfirmController({
    userData,
    open,
    onClose,
    startReservationHour,
    getIncome,
  });

  if (!open) {
    return null;
  }

  return (
    <Container onClick={handleClose}>
      <ContentModal onClick={e => e.stopPropagation()}>
        <Header>
          <Infos>
            <FaUserCircle color="#D8F32E" size={54} />
            <div>
              <Name>{userData.name}</Name>
              <Document>
                <strong>CPF:</strong> {formatCpf(userData.document)}
              </Document>
            </div>
          </Infos>

          <ButtonClose>
            <AiOutlineClose
              size={30}
              color="#818181"
              className="close"
              onClick={handleClose}
            />
          </ButtonClose>
        </Header>

        <Content>
          <Reserve>
            {userData.name} fez a reserva para o dia{' '}
            <span>{reservationDate}</span>
          </Reserve>
          <BottomContent>
            <Reserve background>
              <span>{userData.product.name}</span>
            </Reserve>
            {isFirst && (
              <Tag>
                <span>1x</span>
                Primeira vez
              </Tag>
            )}
          </BottomContent>
        </Content>

        <Description>
          Para confirmar que {userData.name} chegou, insira seu código de
          check-in.
        </Description>

        <Input>
          <CodeNumber
            value={confirmationCode}
            maxLength={6}
            onChange={({ target }) =>
              setConfirmationCode(formatCode(target.value))
            }
          />
          <IconInput>
            {loading ? (
              <Loading width={40} height={40} border={6} />
            ) : (
              <AiFillCheckCircle
                size={40}
                color="#D8F32E"
                onClick={() => onApplyCode()}
              />
            )}
          </IconInput>
        </Input>
      </ContentModal>
    </Container>
  );
};

export default ModalConfirm;
