import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
  margin-bottom: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Button = styled.button`
  width: 500px;
  padding: 9px 12px 9px 18px;
  height: 42px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  color: '#fff';
  background-color: #e1ff2b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 13px;
  font-family: 'Graphik', sans-serif;
  font-weight: 500;
  line-height: 15px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  &:hover {
    opacity: 0.9;
  }
`;
