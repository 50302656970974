import React from 'react';

import CloseIcon from '../components/Icons/Close';
import CheckIcon from '../components/Icons/Check';

import { CardsProps } from '../types';

import {
  CardContainer,
  Badge,
  Header,
  Activity,
  TimeInfo,
  ButtonContainer,
  Button,
  Icon,
  Infos,
} from './styles';

export const AwaitingConfirmation: React.FC<CardsProps> = ({
  name,
  activity,
  date,
  time,
  onAccept,
  onReject,
  ref,
}) => {
  return (
    <CardContainer ref={ref}>
      <Badge>AGUARDANDO SUA CONFIRMAÇÃO</Badge>
      <Header>{name}</Header>
      <Infos>
        <Activity>{activity}</Activity>
        <div className="dot">•</div>
        <TimeInfo>{`${date} • ${time}`}</TimeInfo>
      </Infos>
      <ButtonContainer>
        {onAccept && (
          <Button isAccept onClick={onAccept}>
            Aceitar reserva{' '}
            <Icon>
              <CheckIcon />
            </Icon>
          </Button>
        )}

        {onReject && (
          <Button onClick={onReject}>
            Rejeitar reserva{' '}
            <Icon>
              <CloseIcon />
            </Icon>
          </Button>
        )}
      </ButtonContainer>
    </CardContainer>
  );
};

export default AwaitingConfirmation;
