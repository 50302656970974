import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/nextjs';

import { clientGet, clientPost } from '@/services/client';
import { apiEndpoints } from '@/config/endpoints';
import { ConfirmModalControllerProps } from './types';
import { Checkin } from '@/types/checkin';

import { useConfirmationModalStore } from '@/store/modal/ConfirmationModal/ConfirmationModal';
import useStore from '@/store';

import DateHelper from '@/helpers/dateHelper';

export const useConfirmModalController = ({
  open,
  onClose,
  handleCancel,
}: ConfirmModalControllerProps) => {
  const [checkinData, setCheckinData] = useState<Checkin | null>(null);
  const [loading, setLoading] = useState(false);

  const { updateGetList } = useStore(state => state);

  const { clientName, date, gymClassName, userId, isConfirm } =
    useConfirmationModalStore();

  const handleClose = () => onClose?.(false);

  const onGetCheckin = useCallback(async () => {
    try {
      const response = await clientGet(apiEndpoints.getCheckin(userId));
      setCheckinData(response);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [userId]);

  const tostErrorMessage = () => {
    toast.error('Falha ao confirmar checkin', {
      position: 'top-right',
      autoClose: 1000,
    });
  };

  const handleConfirmAwaitingCheckin = async () => {
    if (loading || !checkinData?.id) return;

    setLoading(true);

    try {
      const response = await clientPost(
        apiEndpoints.confirmAwaitingCheckin(checkinData?.id as string)
      );

      if (response?.status === 'PENDING') {
        toast.success('Checkin confirmado como pendente!', {
          position: 'top-right',
          autoClose: 1000,
        });

        updateGetList(true);
        handleClose();
      } else if (!response) {
        tostErrorMessage();
      }
    } catch (error) {
      tostErrorMessage();
    } finally {
      setLoading(false);
    }
  };

  const handleManualRefundCheckinCheckin = async () => {
    handleClose();
    handleCancel && handleCancel();
  };

  useEffect(() => {
    open && onGetCheckin();
  }, [onGetCheckin, open]);

  const dateHelper = new DateHelper(date);
  const reservationHours = `${dateHelper.formatDateMonth()} às ${dateHelper.formatTime()}`;

  return {
    handleClose,
    isConfirm,
    clientName,
    date,
    gymClassName,
    loading,
    handleConfirmAwaitingCheckin,
    handleManualRefundCheckinCheckin,
    reservationHours,
  };
};

export default useConfirmModalController;
