import * as Sentry from '@sentry/nextjs';

import { clientGet } from '@/services/client';
import { apiEndpoints } from '@/config/endpoints';
import { GetCheckins, GetCheckinsProps } from '@/types/checkin';
import { addMonths, subDays } from 'date-fns';

const defaultValue = {
  currentPage: 0,
  data: [],
  totalPages: 0,
  totalResults: 0,
};

const getCheckins = async ({
  pageParam,
}: GetCheckinsProps): Promise<GetCheckins> => {
  const id = pageParam?.establishmentId;
  const page = pageParam?.page ?? 1;

  if (!id) return defaultValue;

  try {
    const response = await clientGet(
      `${apiEndpoints.getAllCheckins(
        id
      )}?page=${page}&limit=50&startDate=${subDays(
        new Date(),
        2
      ).toISOString()}&endDate=${addMonths(new Date(), 1).toISOString()}`
    );

    const checkins: GetCheckins = response;

    return checkins;
  } catch (error) {
    Sentry.captureException(error);
    return defaultValue;
  }
};

export default getCheckins;
